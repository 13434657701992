<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600px" scrollable transition="dialog-transition" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <div v-if="guildMatch.status === 'pending'">
          <h2 class="d-block mb-4" v-text="'Asignar entradas'" />
          <v-alert type="error">
            {{ $t('cluster.tickets.assignTickets.errorMessage') }}
          </v-alert>
        </div>
        <div v-else>
          <div class="d-flex align-center mb-4">
            <img v-if="guild.logo" :src="guild.logo" width="48">
            <avatar-field
              v-else
              :active="false" custom-size="48" custom-icon="none"
            />
            <h4 class="font-weight-bold ml-2 text-truncate" v-text="guild.name" />
          </div>
          <h4 class="font-weight-bold mb-6" v-text="$t('project.sections.generalInformation')" />
          <v-row>
            <v-col cols="12" md="6" class="py-1 px-3">
              <v-text-field
                :value="guild.requested" :label="$t('cluster.tickets.assignTickets.ticketsRequestedByTheGuild')"
                outlined dense disabled
              />
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
              <v-text-field
                :value="guildMatch.available" :label="$t('cluster.tickets.assignTickets.ticketsAvailableToCluster')"
                outlined dense disabled
              />
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
              <v-text-field
                :value="guild.accepted" :label="$t('cluster.tickets.assignTickets.ticketsAccepted')"
                outlined dense disabled
              />
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
              <v-text-field
                :value="guild.purchased" :label="$t('cluster.tickets.assignTickets.ticketsPurchased')"
                outlined dense disabled
              />
            </v-col>
          </v-row>
          <h4 class="font-weight-bold mb-3" v-text="$t('cluster.tickets.assignTickets.assignmentTicketsToGuild')" />
          <p v-text="$t('cluster.tickets.assignTickets.numberOfTicketsAssignToGuild')" />
          <v-text-field
            v-model.number="form.ticketCount"
            :error-messages="[
              ...(!$v.form.ticketCount.isLessThanTicketsAvailable && form.ticketCount
                ? [$t('cluster.tickets.assignTickets.errorNumberTicketsGreaterThanAvailableTicketsToCluster')] : [] ),
              ...(!$v.form.ticketCount.isGreaterThanTickestAcceptedOrPurchased && form.ticketCount
                ?
                  [$t('cluster.tickets.assignTickets.errorNumberTicketsLessThanTicketsAcceptedOrPurchased')] : [])
            ]"
            :label="$t('cluster.tickets.assignTickets.ticketCount')" type="number" min="1" outlined dense
            @keypress="validateKeyNumeric($event)"
          />
        </div>
        <v-card-actions class="pa-0 d-flex justify-space-around">
          <v-btn class="px-4 w-100 w-sm-auto" color="primary" @click="assignTickets" v-text="$t('cluster.tickets.assignTickets.assignTickets')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import formUtils from '@/utils/mixins/formUtils'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AssignTicketsDialog',
  components: {
    AvatarField: () => import('@/components/AvatarField'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    cluster: { type: Object, required: true },
    guild: { type: Object, required: true },
    guildMatch: { type: Object, required: true },
  },
  data: () => ({ form: { ticketCount: null } }),
  validations() {
    return {
      form: {
        ticketCount: {
          required,
          isLessThanTicketsAvailable: value => value <= (this.guildMatch.available + this.guild.assignedTicketCount),
          isGreaterThanTickestAcceptedOrPurchased: value => value >= this.guild.accepted,
        },
      },
    }
  },
  methods: {
    async assignTickets() {
      if (!this.isFormValid()) return
      const { organizationId, cluster, guildMatch: { id: guildMatchId }, form: { ticketCount }, guild: project } = this
      this.runAsync(async () => {
        await this.runAsync(() => {
          this.$store.dispatch('guildMatches/assignTicketsToGuild', { organizationId, project, cluster, guildMatchId, ticketCount })
        })
      })
      this.$emit('closeDialog')
    },
  },
}
</script>
